import Vue from "vue";
import Router from "vue-router"; 
// import VueGraph from 'vue-graph'

// Axios
import axios from "axios";

// Main layouts
import LayoutBackend from "@/layouts/variations/Backend.vue";
// import LayoutSimple from '@/layouts/variations/Simple.vue'

// Register Vue Router
Vue.use(Router);
// Vue.use(VueGraph);

// Router Configuration
const router = new Router({
  linkActiveClass: "",
  linkExactActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  // mode: 'hash',
  mode: "history",
  routes: [
    // Routes:
    // Login
    {
      path: "*",
      name: "NotFound",
      redirect: "/"
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("../views/mobile/login/Login.vue")
    },

    {
      path: "/s/:id?",
      name: "redirectWA",
      component: () => import("../views/mobile/others/ForwarderPage.vue")
    },

    {
      path: "/formInstan/:id?",
      name: "formInstanEmployee",
      component: () => import("../views/mobile/others/formInstan.vue")
    },


    {
      path: "/",
      name: "Home",
      redirect: "/dashboard",
      component: LayoutBackend,
      children: [
        // Dashboard
        {
          path: "dashboard",
          name: "Dashboard",
          component: () => import("@/views/Dashboard.vue")
        },

        // Dashboard admin
        {
          path: "admin-dashboard",
          name: "AdminDashboard",
          component: () => import("@/views/AdminDashboard.vue"),
          meta: {
            permission: "DASHBOARD_EMPLOYEE_GRAPH"
          }
        },

        // Pengaturan
        {
          path: "settings",
          name: "Settings",
          component: () => import("@/views/mobile/pengaturan/Pengaturan.vue")
        },
        {
          path: "settings/profile",
          name: "SettingProfile",
          component: () =>
            import("@/views/mobile/pengaturan/PengaturanProfile.vue")
        },
        {
          path: "settings/jobs",
          name: "SettingJobs",
          component: () =>
            import("@/views/mobile/pengaturan/PengaturanPekerjaan.vue")
        },
        {
          path: "settings/assetonhand",
          name: "SettingAssetOnHand",
          component: () =>
            import("@/views/mobile/pengaturan/PengaturanAssetOnHand.vue")
        },
        {
          path: "settings/loan",
          name: "SettingLoan",
          component: () =>
            import("@/views/mobile/pengaturan/PengaturanLoan.vue")
        },
        {
          path: "settings/loan/history",
          name: "SettingLoanHistory",
          component: () =>
            import("@/views/mobile/pengaturan/PengaturanLoanHistory.vue")
        },
        {
          path: "settings/loan/pay-history",
          name: "SettingLoanPayHistory",
          component: () =>
            import("@/views/mobile/pengaturan/PengaturanLoanPayHistory.vue")
        },

        {
          path: "settings/company",
          name: "SettingCompany",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanCompany.vue")
        },
        {
          path: "settings/other",
          name: "SettingOther",
          component: () =>
            import("@/views/mobile/pengaturan/PengaturanLainya.vue")
        },
        // {
        //   path: "settings/bank",
        //   name: "SettingBank",
        //   component: () =>
        //     import("../views/mobile/pengaturan/PengaturanBank.vue")
        // },
        {
          path: "settings/password",
          name: "SettingPassword",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanPassword.vue")
        },
        {
          path: "settings/bank-3",
          name: "SettingBank3",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanBankPihak3.vue")
        },

        // Login Devices
        {
          path: "/logindevices",
          name: "LoginDevices",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanLoginDevices.vue")
        },

        // Setting Reprimand
        {
          path: "settings/reprimand",
          name: "SettingReprimand",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanReprimand.vue")
        },
        {
          path: "settings/reprimand/detail/:id?",
          name: "SettingReprimandDetail",
          component: () =>
            import("../views/mobile/pengaturan/ReprimandDetail.vue")
        },

        {
          path: "/cekdevices",
          name: "CekDevices",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanCekDevices.vue")
        },

        {
          path: "settings/gaji",
          name: "SettingGaji",
          component: () =>
            import("../views/mobile/pengaturan/PengaturanGaji.vue")
        },

        ///////////////////////////////////////////////////////////
        /// Kehadiran
        ///////////////////////////////////////////////////////////
        {
          path: "attendance",
          name: "Attendance",
          component: () => import("../views/mobile/attendance/Attendance.vue")
        },

        {
          path: "attendance/picture",
          name: "AttendancePicture",
          component: () =>
            import("../views/mobile/attendance/AttendancePicture.vue")
        },

        {
          path: "attendance/form",
          name: "AttendanceForm",
          component: () =>
            import("../views/mobile/attendance/AttendanceForm.vue")
        },

        {
          path: "attendance/history",
          name: "AttendanceHistory",
          component: () =>
            import("../views/mobile/attendance/AttendanceHistory.vue")
        },

        {
          path: "attendance/request/form",
          name: "AttendanceRequestForm",
          component: () =>
            import("../views/mobile/attendance/AttendanceRequestForm.vue")
        },
        {
          path: "attendance/request/history",
          name: "AttendanceRequestHistory",
          component: () =>
            import("../views/mobile/attendance/AttendanceRequestHistory.vue")
        },

        // lembur
        {
          path: "attendance/overtime/form",
          name: "AttendanceOverTimeForm",
          component: () =>
            import("../views/mobile/attendance/AttendanceOverTimeForm.vue")
        },

        {
          path: "overtime/report",
          name: "OverTimeReport",
          component: () =>
            import("../views/mobile/overtime/OverTimeReport.vue"),
          meta: {
            permission: "OVERTIME_REPORT_VIEW"
          }
        },

        {
          path: "attendance/overtime/history",
          name: "AttendanceOverTimeHistory",
          component: () =>
            import("../views/mobile/attendance/AttendanceOverTimeHistory.vue")
        },

        // tukar shift
        {
          path: "attendance/exchangeshift/form",
          name: "AttendanceExChangeShiftForm",
          component: () =>
            import("../views/mobile/attendance/AttendanceExChangeShiftForm.vue")
        },

        {
          path: "attendance/exchangeshift/history",
          name: "AttendanceExChangeShiftHistory",
          component: () =>
            import("../views/mobile/attendance/AttendanceExChangeShiftHistory.vue")
        },

        {
          path: "exchangeshift/report",
          name: "AttendanceExChangeShiftReport",
          component: () =>
            import("../views/mobile/exchangeshift/ExChangeShiftReport.vue"),
          meta: {
            permission: "TIME_SLOT_CHANGE_REPORT_VIEW"
          }
        },

        {
          path: "attendance/admin/report",
          name: "AttendanceReport",
          component: () =>
            import("@/views/mobile/attendance/admin/AttendanceReport.vue"),
          meta: {
            permission: "ATTENDANCE_REPORT_VIEW"
          }
        },
        {
          path: "attendance/admin/timeoffreport",
          name: "TimeOffReport",
          component: () =>
            import("@/views/mobile/attendance/admin/TimeOffReport.vue"),
          meta: {
            permission: "TIME_OFF_REPORT_VIEW"
          }
        },
        {
          path: "attendance/admin/dailyreport",
          name: "DailyAttendanceReport",
          component: () =>
            import("@/views/mobile/attendance/admin/DailyAttendanceReport.vue"),
          meta: {
            permission: "ATTENDANCE_DAILY_VIEW"
          }
        },
        {
          path: "attendance/admin/dailyattendancereport",
          name: "DailyAttendanceReport2",
          component: () =>
            import("@/views/mobile/attendance/admin/DailyAttendanceReport2.vue"),
          meta: {
            permission: "ATTENDANCE_DAILY_VIEW"
          }
        },

        {
          path: "attendance/admin/shift",
          name: "AttendanceShift",
          component: () =>
            import("@/views/mobile/attendance/admin/AttendanceReport2.vue"),
          meta: {
            permission: "SHIFT_SETTING"
          }
        },

        {
          path: "attendancerequest/report",
          name: "AttendanceRequestReport",
          component: () =>
            import("@/views/mobile/attendance/admin/AttendanceReqeustReport.vue"),
          meta: {
            permission: "ATTENDANCE_REQUEST_VIEW"
          }
        },

        ///////////////////////////////////////////////////////////
        /// Istirahat
        ///////////////////////////////////////////////////////////
        {
          path: "break",
          name: "Break",
          component: () => import("../views/mobile/break/Attendance.vue")
        },

        {
          path: "break/picture",
          name: "BreakPicture",
          component: () =>
            import("../views/mobile/break/AttendancePicture.vue")
        },

        {
          path: "break/form",
          name: "BreakForm",
          component: () =>
            import("../views/mobile/break/AttendanceForm.vue")
        },

        {
          path: "break/history",
          name: "BreakHistory",
          component: () =>
            import("../views/mobile/break/AttendanceHistory.vue")
        },

        {
          path: "break/request/form",
          name: "BreakRequestForm",
          component: () =>
            import("../views/mobile/break/AttendanceRequestForm.vue")
        },

        // {
        //   path: "break/admin/report",
        //   name: "BreakReport",
        //   component: () =>
        //     import("@/views/mobile/break/admin/AttendanceReport.vue"),
        //   meta: {
        //     permission: "ATTENDANCE_REPORT_VIEW"
        //   }
        // },
        {
          path: "break/admin/breakreport",
          name: "BreakReport",
          component: () =>
            import("@/views/mobile/break/admin/BreakReport.vue"),
          meta: {
            permission: "ATTENDANCE_REPORT_VIEW"
          }
        },

        ///////////////////////////////////////////////////////////
        /// Visitation
        ///////////////////////////////////////////////////////////
        {
          path: "visitation",
          name: "Visitation",
          component: () => import("../views/mobile/visitation/Attendance.vue")
        },

        {
          path: "visitation/picture",
          name: "VisitationPicture",
          component: () =>
            import("../views/mobile/visitation/AttendancePicture.vue")
        },

        {
          path: "visitation/form",
          name: "VisitationForm",
          component: () =>
            import("../views/mobile/visitation/AttendanceForm.vue")
        },

        {
          path: "visitation/history",
          name: "VisitationHistory",
          component: () =>
            import("../views/mobile/visitation/AttendanceHistory.vue")
        },

        {
          path: "visitation/request/form",
          name: "VisitationRequestForm",
          component: () =>
            import("../views/mobile/visitation/AttendanceRequestForm.vue")
        },

        // {
        //   path: "canvassing/admin/report",
        //   name: "CanvassingReport",
        //   component: () =>
        //     import("@/views/mobile/canvassing/admin/AttendanceReport.vue"),
        //   meta: {
        //     permission: "CHECKPOIN_CANVASSING_VIEW"
        //   }
        // },
        {
          path: "visitation/admin/report",
          name: "VisitationReport2",
          component: () =>
            import("@/views/mobile/visitation/admin/AttendanceReport2.vue"),
          meta: {
            permission: "VISITATION_REPORT_VIEW"
          }
        },

        // kuota cuti
        {
          path: "cuti/admin/report",
          name: "cutiReport",
          component: () =>
            import("@/views/mobile/cuti/admin/AttendanceReport2.vue"),
          meta: {
            permission: "TIME_OFF_SETTING"
          }
        },

        {
          path: "mod/",
          name: "ManagerOnDuty",
          component: () =>
            import("@/views/mobile/managerOnDuty/view.vue"),
          meta: {
            permission: "MANAGER_ON_DUTY_VIEW" // 
          }
        },
        {
          path: "attend/admin/setting",
          name: "AttendanceSetting",
          component: () =>
            import("@/views/mobile/attendance/admin/AttendanceSetting.vue"),
          meta: {
            permission: "ATTENDANCE_SETTING"
          }
        },

        // Approval Attendance
        {
          path: "/approval/attendance_request/:id?",
          name: "ApprovalAttendanceRequest",
          component: () => import("../views/mobile/approval/ApprGlobal.vue")
        },
 
        ///////////////////////////////////////////////////////////
        /// Cuti
        ///////////////////////////////////////////////////////////
        {
          path: "timeoff",
          name: "Timeoff",
          component: () => import("../views/mobile/timeoff/Timeoff.vue")
        },

        {
          path: "timeoff/form",
          name: "TimeoffForm",
          component: () => import("../views/mobile/timeoff/TimeoffForm.vue")
        },

        {
          path: "timeoff/detail/:id?",
          name: "TimeoffDetail",
          component: () => import("../views/mobile/timeoff/TimeoffDetail.vue")
        },

        // Approval TimeOff
        {
          path: "/approval/timeoff/:id?",
          name: "ApprovalTimeOff",
          component: () => import("../views/mobile/approval/ApprGlobal.vue")
        },

        ///////////////////////////////////////////////////////////
        /// Reimburse
        ///////////////////////////////////////////////////////////
        {
          path: "reimburse",
          name: "Reimburse",
          component: () => import("../views/mobile/reimburse/ReimburseHome.vue")
        },
        {
          path: "reimburse/add/:id?",
          name: "ReimburseAdd",
          component: () =>
            import("../views/mobile/reimburse/ReimburseTambah.vue")
        },
        {
          path: "reimburse/detail/:id",
          name: "ReimburseDetail",
          component: () =>
            import("../views/mobile/reimburse/ReimburseDetail.vue")
        },
        {
          path: "reimburse/riwayat/:id?",
          name: "ReimburseHistory",
          component: () =>
            import("../views/mobile/reimburse/ReimburseRiwayat.vue")
        },

        ///////////////////////////////////////////////////////////
        /// Approval
        ///////////////////////////////////////////////////////////
        {
          path: "approval",
          name: "Approval",
          component: () => import("../views/mobile/approval/Approval.vue")
        },
        {
          path: "approval/history",
          name: "ApprovalHistory",
          component: () =>
            import("../views/mobile/approval/ApprovalHistory.vue")
        },
        {
          path: "approval/topuppc/:id?",
          name: "ApprovalTopUpPettyCash",
          component: () =>
            import("../views/mobile/approval/ApprTopUpPettyCash.vue")
        },

        // Approval Cash Advance
        {
          path: "approval/pengajuan/:id?",
          name: "ApprovalCAPengajuan",
          component: () =>
            import("../views/mobile/approval/ApprCAPengajuan.vue")
        },
        {
          path: "approval/realisasi/:id?",
          name: "ApprovalCARealisasi",
          component: () =>
            import("../views/mobile/approval/ApprCARealisasi.vue")
        },

        // Approval Over Time
        {
          path: "/approval/attendance_overtime/:id?",
          name: "ApprovalAttendanceOverTime",
          component: () => import("../views/mobile/approval/ApprGlobal.vue")
        },

        // Approval Change Request
        {
          path: "/approval/attendance_changerequest/:id?",
          name: "ApprovalAttendanceChangeRequest",
          component: () => import("../views/mobile/approval/ApprGlobal.vue")
        },

        // Approval Employee Request
        {
          path: "/approval/addemployeerequest/:id?",
          name: "ApprovalEmployeeRequest",
          component: () => import("../views/mobile/approval/ApprGlobal.vue")
        },

        // Approval Reprimand
        {
          path: "/approval/reprimand/:id?",
          name: "ApprovalReprimand",
          component: () => import("../views/mobile/approval/ApprGlobal.vue")
        },

        {
          path: "reprimand/detail/:id?",
          name: "ReprimandDetail",
          component: () =>
            import("../views/mobile/suratperingatan/ReprimandDetail.vue")
        }, 

        // Cash Advance
        {
          path: "cashadvance",
          name: "CashAdvance",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceHome.vue")
        },
        {
          path: "cashadvance/add",
          name: "CashAdvanceAdd",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceTambah.vue")
        },
        {
          path: "cashadvance/history",
          name: "CashAdvanceHistory",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceRiwayat.vue")
        },
        {
          path: "cashadvance/detail/:id?",
          name: "CashAdvanceDetail",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceDetail.vue")
        },
        {
          path: "cashadvance/additional/:id?",
          name: "CashAdvanceAdditional",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceTambahPengajuan.vue")
        },
        {
          path: "cashadvance/realization/:id?",
          name: "CashAdvanceRealization",
          component: () =>
            import(
              "../views/mobile/cashadvance/CashAdvanceTambahPengeluaran.vue"
            )
        },
        {
          path: "cashadvance/realization/edit/:id?",
          name: "CashAdvanceEditRealization",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceEditPengeluaran.vue")
        },
        {
          path: "cashadvance/request/edit/:id?/:main?",
          name: "CashAdvanceEditRequest",
          component: () =>
            import("../views/mobile/cashadvance/CashAdvanceEditPengajuan.vue")
        },

        // Finance
        {
          path: "finance/reimburse",
          name: "FinanceReimburse",
          component: () =>
            import("../views/mobile/finance/ApprovalReimburse.vue"),
          meta: {
            permission: "CASH_REIMBURSE_VIEW"
          }
        },
        {
          path: "finance/approval/:id",
          name: "FinanceApprovalReimburse",
          component: () =>
            import("../views/mobile/finance/ApprReimburseDetail.vue"),
          meta: {
            permission: "CASH_REIMBURSE_VIEW"
          }
        },
        {
          path: "finance/cashadvance",
          name: "FinanceCashAdvance",
          component: () =>
            import("../views/mobile/finance/ApprovalCashAdvance.vue"),
          meta: {
            permission: "CASH_ADVANCE_VIEW"
          }
        },
        {
          path: "finance/cashadvance/:id?",
          name: "FinanceApprovalCashAdvance",
          component: () =>
            import("../views/mobile/finance/ApprCashAdvanceDetail.vue"),
          meta: {
            permission: "CASH_ADVANCE_VIEW"
          }
        },

        // Kasir
        {
          path: "finance/kasir",
          name: "Kasir",
          component: () => import("../views/mobile/kasir/ApprovalReimburse.vue")
        },
        {
          path: "finance/kasir/approval/:id/:ispc?",
          name: "KasirApproval",
          component: () =>
            import("../views/mobile/kasir/ApprReimburseDetail.vue")
        },

        // Payment Voucher
        {
          path: "paymentvoucher",
          name: "PaymentVoucher",
          component: () =>
            import("../views/mobile/paymentvoucher/PaymentVoucher.vue"),
          meta: {
            permission: "PAYMENT_VOUCHER_VIEW"
          }
        },
        {
          path: "paymentvoucher/detail/:id",
          name: "PaymentVoucherDetail",
          component: () =>
            import("../views/mobile/paymentvoucher/PaymentVoucherDetail.vue"),
          meta: {
            permission: "PAYMENT_VOUCHER_VIEW"
          }
        },

        // Employee
        {
          path: "employee",
          name: "Employee",
          component: () => import("../views/mobile/karyawan/Employee.vue"),
          meta: {
            permission: "EMPLOYEE_VIEW"
          }
        },
        {
          path: "employee/register",
          name: "EmployeeRegister",
          component: () => import("../views/mobile/karyawan/EmployeeRegister.vue"),
          meta: {
            permission: "EMPLOYEE_CREATE"
          }
        },
        {
          path: "employee/form",
          name: "EmployeeForm",
          component: () => import("../views/mobile/karyawan/EmployeeForm.vue"),
          meta: {
            permission: "EMPLOYEE_VIEW"
          }
        },

        {
          path: "employee/resign",
          name: "EmployeeResign",
          component: () => import("../views/mobile/karyawan/EmployeeResign.vue"),
          meta: {
            permission: "EMPLOYEE_VIEW"
          }
        },
        // kontrak

        {
          path: "employee/contract",
          name: "EmployeeContract",
          component: () => import("../views/mobile/karyawan/EmployeeKontrak.vue"),
          meta: {
            permission: "EMPLOYEE_CONTRACT_VIEW"
          }
        },

        {
          path: "reprimand",
          name: "Reprimand",
          component: () =>
            import("@/views/mobile/suratperingatan/view.vue"),
          meta: {
            permission: "REPRIMAND_LETTER_VIEW"
          }
        },

        // Information
        {
          path: "information/company",
          name: "InformationCompany",
          component: () => import("../views/mobile/information/Company.vue"),
          meta: {
            permission: "COMPANY_SETTING"
          }
        },
        // {
        //   path: "information/company/add",
        //   name: "CompanyAdd",
        //   component: () => import("../views/mobile/information/CompanyAdd.vue"),
        //   meta: {
        //     permission: "COMPANY_SETTING"
        //   }
        // },
        // {
        //   path: "information/company/edit/:id?",
        //   name: "CompanyEdit",
        //   component: () =>
        //     import("../views/mobile/information/CompanyEdit.vue"),
        //   meta: {
        //     permission: "COMPANY_SETTING"
        //   }
        // },
        // {
        //   path: "information/company/detail/:id?",
        //   name: "InformationCompanyDetail",
        //   component: () =>
        //     import("../views/mobile/information/CompanyDetail.vue"),
        //   meta: {
        //     permission: "COMPANY_SETTING"
        //   }
        // },
        {
          path: "information/shift",
          name: "InformationShift",
          component: () => import("../views/mobile/information/KodeShift.vue"),
          meta: {
            permission: "TIME_SLOT_SETTING_VIEW"
          }
        },
        {
          path: "information/category",
          name: "InformationCategory",
          component: () => import("../views/mobile/information/Category.vue"),
          meta: {
            permission: "CATEGORY_SETTING"
          }
        },
        {
          path: "information/emptitle",
          name: "InformationEmpTitle",
          component: () => import("../views/mobile/karyawan/TitleKaryawan.vue"),
          meta: {
            permission: "EMPLOYEE_TITLE_SETTING"
          } 
        }, 
        
        {
          path: "information/division",
          name: "InformationDivision",
          component: () => import("../views/mobile/information/Division.vue"),
          meta: {
            permission: "DIVISION_SETTING"
          }
        },
        {
          path: "information/settingapproval",
          name: "InformationApproval",
          component: () => import("../views/mobile/information/Approval.vue"),
          meta: {
            permission: "APPROVAL_SETTING"
          }
        },
        {
          path: "information/loancategory",
          name: "InformationKategoriPinjaman",
          component: () => import("../views/mobile/loan/KodePinjaman.vue"),
          meta: {
            permission: "LOAN_CATEGORY_SETTING"
          }
        },
        {
          path: "information/contactnumber",
          name: "InformationContact",
          component: () => import("../views/mobile/information/Contact.vue"),
          meta: {
            permission: "USER_CONTACT_BOOK"
          }
        },
        {
          path: "information/calendar",
          name: "InformationCalendar",
          component: () => import("../views/mobile/information/Calendar.vue"),
          meta: {
            permission: "USER_CALENDAR"
          }
        },

        // Company
        {
          path: "company/location",
          name: "CompanyLocation",
          component: () =>
            import("../views/mobile/company/CompanyLocation.vue"),
          meta: {
            permission: "ATTENDANCE_LOCATION_SETTING"
          }
        },
        {
          path: "company/timeoff-type",
          name: "CompanyTimeoffType",
          component: () =>
            import("../views/mobile/company/CompanyCutiType.vue"),
          meta: {
            permission: "TIME_OFF_TYPE_SETTING"
          }
        },
        {
          path: "company/managerial",
          name: "CompanyManagerial",
          component: () =>
            import("../views/mobile/company/CompanyManagerial.vue"),
          meta: {
            permission: "SETTING_MANAGERIAL_VIEW"
          }
        },
        {
          path: "company/category",
          name: "CompanyCategory",
          component: () =>
            import("../views/mobile/company/CompanyCategory.vue"),
          meta: {
            permission: "SETTING_CATEGORY_VIEW"
          }
        },
        {
          path: "company/pettycash",
          name: "CompanyPettyCash",
          component: () =>
            import("../views/mobile/company/CompanyPettyCash.vue"),
          meta: {
            permission: "PETTY_CASH_TOPUP_VIEW"
          }
        },
        {
          path: "company/pettycash/detail/:id?",
          name: "CompanyPettyCashDetail",
          component: () =>
            import("../views/mobile/company/CompanyPettyCashDetail.vue"),
          meta: {
            permission: "PETTY_CASH_TOPUP_VIEW"
          }
        },

        // Petty Cash
        // {
        //   path: "pettycash",
        //   name: "PettyCash",
        //   component: () => import("../views/mobile/pettycash/PettyCash.vue"),
        //   meta: {
        //     permission: "PETTY_CASH_TOPUP_VIEW"
        //   }
        // },
        {
          path: "pettycash/detail/:id?",
          name: "PettyCashDetail",
          component: () =>
            import("../views/mobile/pettycash/PettyCashDetail.vue"),
          meta: {
            permission: "PETTY_CASH_TOPUP_VIEW"
          }
        },
        {
          path: "pettycash/topup",
          name: "PettyCashTopup",
          component: () =>
            import("../views/mobile/pettycash/PettyCashTopUp.vue"),
          meta: {
            permission: "PETTY_CASH_TOPUP_VIEW"
          }
        },

        // Announcement
        {
          path: "announcement",
          name: "Announcement",
          component: () =>
            import("../views/mobile/announcement/Announcement.vue"),
          meta: {
            permission: "ANNOUNCEMENT_VIEW"
          }
        },
        {
          path: "announcement/create",
          name: "AnnouncementCreate",
          component: () =>
            import("../views/mobile/announcement/AnnouncementCreate.vue"),
          meta: {
            permission: "ANNOUNCEMENT_MODIFY"
          }
        },
        {
          path: "announcement/detail/:id?",
          name: "AnnouncementDetail",
          component: () =>
            import("../views/mobile/announcement/AnnouncementDetail.vue")
        },
        {
          path: "announcement/edit/:id?",
          name: "AnnouncementEdit",
          component: () =>
            import("../views/mobile/announcement/AnnouncementEdit.vue"),
          meta: {
            permission: "ANNOUNCEMENT_MODIFY"
          }
        },

        {
          path: "employe-mutation",
          name: "EmployeMutation",
          component: () =>
            import("../views/mobile/mutasikaryawan/mutasiview.vue"),
          meta: {
            permission: "EMPLOYEE_MUTATION"
          }
        },

        // Company Bank
        {
          path: "/companybank",
          name: "CompanyBank",
          component: () => import("../views/mobile/companybank/CompanyBank.vue"),
          meta: {
            permission: "COMPANY_BANK_VIEW"
          }
        },

        // Notification
        {
          path: "/notifikasi",
          name: "Notifikasi",
          component: () => import("../views/mobile/notifikasi/Notifikasi.vue")
        },

        // Surat Penugasan
        {
          path: "/surattugas",
          name: "SuratTugas",
          component: () => import("../views/mobile/surattugas/SuratTugas.vue")
        },
        {
          path: "/surattugas/create",
          name: "SuratTugasCreate",
          component: () =>
            import("../views/mobile/surattugas/SuratTugasCreate.vue")
        },
        {
          path: "/surattugas/detail/:id?",
          name: "SuratTugasDetail",
          component: () =>
            import("../views/mobile/surattugas/SuratTugasDetail.vue")
        },
        {
          path: "/surattugas/history",
          name: "SuratTugasHistory",
          component: () =>
            import("../views/mobile/surattugas/SuratTugasHistory.vue")
        },
        {
          path: "/surattugas/manage",
          name: "SuratTugasManage",
          component: () =>
            import("../views/mobile/surattugas/SuratTugasManage.vue"),
          meta: {
            permission: "ASSIGNMENT_LETTER_REPORT_VIEW"
          }
        },

        // Approval Surat Tugas
        {
          path: "/approval/surattugas/:id?",
          name: "ApprovalSuratTugas",
          component: () => import("../views/mobile/approval/ApprSuratTugas.vue")
        },

        // Change Log
        {
          path: "/changelog",
          name: "ChangeLog",
          component: () => import("../views/mobile/changelog/Changelog.vue")
        },

        //vendor
        {
          path: "/vendor",
          name: "vendor",
          component: () => import("../views/mobile/vendor/view.vue"),
          meta: {
            permission: "VENDOR_VIEW"
          }
        },

        //asset
        {
          path: "/asset",
          name: "Asset",
          component: () => import("../views/mobile/asset/view.vue"),
          meta: {
            permission: "ASSET_VIEW"
          }
        },

        //maintenance
        {
          path: "/maintenance",
          name: "Maintenance",
          component: () => import("../views/mobile/maintenance/view.vue"),
          meta: {
            permission: "ASSET_MAINTENANCE_VIEW"
          }
        },

        //handover
        {
          path: "/handover",
          name: "Handover",
          component: () => import("../views/mobile/handover/view.vue"),
          meta: {
            permission: "ASSET_HANDOVER_VIEW"
          }
        },
        // super
        {
          path: "/messagehistory",
          name: "MessageHistory",
          component: () => import("../views/mobile/super/MessageHistory.vue"),
          meta: {
            permission: "MESSAGE_HISTORY_VIEW"
          }
        },
        {
          path: "company/setting",
          name: "SettingParam",
          component: () =>
            import("@/views/mobile/super/SettingParam.vue"),
          meta: {
            permission: "COMPANY_CONFIG_VIEW"
          }
        },
        {
          path: "/employe-activity",
          name: "EmployeeActivity",
          component: () =>
            import("@/views/mobile/super/EmployeeActivity.vue"),
          meta: {
            permission: "EMPLOYEE_ACTIVITY_VIEW"
          }
        },


        {
          path: "/petty-cash",
          name: "PettyCash",
          component: () => import("../views/mobile/keuangan/pettycash/view.vue"),
          meta: {
            permission: "PETTYCASH_ACCOUNT_VIEW"
          }
        },

        {
          path: "petty-cash/ledger/:id?",
          name: "LedgerPettyCash",
          component: () => import("../views/mobile/keuangan/ledger-pettycash/view.vue"),
          meta: {
            permission: "PETTYCASH_LEDGER_VIEW"
          }
           
        },

        // payroll
        {
          path: "payroll/setting",
          name: "PayrollSetting",
          component: () =>
            import("../views/mobile/payroll/PayrollConfig.vue"),
          meta: {
            permission: "PAYROLL_CONFIGURATION"
          }
        },
        {
          path: "payroll/payroll-employee/:id?",
          name: "PayrollEmployee",
          component: () =>
            import("../views/mobile/payroll/PayrollEmployee.vue"),
          meta: {
            permission: "PAYROLL_CONFIGURATION"
          }
        },

        {
          path: "payroll/list",
          name: "PayrollList",
          component: () =>
            import("../views/mobile/payroll/PayrollList.vue"),
          meta: {
            permission: "PAYROLL_GENERATE_VIEW"
          }
        },
        {
          path: "payroll/list/:id?",
          name: "PayrollListDetail",
          component: () =>
            import("../views/mobile/payroll/PayrollListDetail.vue"),
          meta: {
            permission: "PAYROLL_GENERATE_MOD"
          }
        },

        // loan 
        {
          path: "loan",
          name: "LoanView",
          component: () =>
            import("../views/mobile/loan/LoanView.vue"),
          meta: {
            permission: "LOAN_VIEW"
          }
        },

        {
          path: "loan/create",
          name: "LoanCreate",
          component: () =>
            import("../views/mobile/loan/LoanCreate.vue"),
          meta: {
            permission: "LOAN_VIEW"
          }
        },

        {
          path: "loan/list/:id?",
          name: "LoanListDetail",
          component: () =>
            import("../views/mobile/loan/LoanListDetail.vue"),
          meta: {
            permission: "LOAN_MOD"
          }
        },

        {
          path: "loan/list-detail/:id?",
          name: "LoanListDetailD",
          component: () =>
            import("../views/mobile/loan/LoanListDetailD.vue"),
          meta: {
            permission: "LOAN_MOD"
          }
        },
        // Approval Loan
        {
          path: "/approval/loan/:id?",
          name: "ApprovalLoan",
          component: () => import("../views/mobile/approval/ApprGlobalGroup.vue")
        },
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && to.name !== "formInstanEmployee") {
    if (localStorage.getItem("user-info") === null) {
      next({ name: "Login" });
      return;
    }

    let permission = to.meta.permission;
    if (permission != undefined) {
      const userPermissions = JSON.parse(
        localStorage.getItem("user-info")
      );
      if (!userPermissions.menu_permission.includes(to.meta.permission)) {
        next({ name: "Dashboard" });
      }
    }

    //check auth
    const api = process.env.VUE_APP_API_URL;
    // const userInfo = JSON.parse(localStorage.getItem("user-info"));
    const token = localStorage.getItem("user-token");
    const config = {
      headers: {
        "x-api-key": token
        // "x-api-key": userInfo.em_login_token
      }
    };

    axios
      .post(
        api + "/v2/check-auth",
        {
          app_v: process.env.VUE_APP_VERSION
        },
        config
      )
      .then(res => {
        if (res.status === 200) { 
          if (from.name === 'EmployeeRegister') {
            localStorage.setItem("data_employee", JSON.stringify(null));
            localStorage.setItem("data_company", JSON.stringify(null));
            localStorage.setItem("data_payroll", JSON.stringify(null));
            localStorage.setItem("data_review", JSON.stringify(null));
          }
          next();
        }
      })
      .catch(error => {
        if (error.response) {
          // let data = error.response.data;
          // let header = error.response.headers;
          let code = error.response.status;
          if (code == 400 || code == 500) {
            localStorage.clear();
            next({ name: "Login" });
          }
        }
        next({ name: "Dashboard" });
      });
  } else {
    next();
  }
});

// router.afterEach((to, from) => {
//   console.log('aftereach call');
//   console.log(from);

// })

export default router;
