var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    {
      staticStyle: { "background-color": "#fafafa", "box-shadow": "none" },
      attrs: { id: "page-header" }
    },
    [
      _vm._t("default", [
        _c(
          "div",
          {
            staticClass: "content-header",
            staticStyle: { "margin-left": "15px" }
          },
          [
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c(
                  "base-layout-modifier",
                  {
                    staticClass: "mr-2 d-lg-none",
                    attrs: {
                      action: "sidebarToggle",
                      size: "sm",
                      variant: "dual"
                    }
                  },
                  [_c("i", { staticClass: "fa fa-fw fa-bars" })]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "mr-2 d-lg-none d-flex" },
                  [
                    _c("img", {
                      staticClass: "cursor-pointer",
                      staticStyle: { height: "30px" },
                      attrs: { src: require("@/assets/icons/logo_color.png") },
                      on: {
                        click: function($event) {
                          return _vm.$router.push({ name: "Dashboard" })
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("AddToHomeScreen", { staticClass: "ml-2" })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "base-layout-modifier",
                  {
                    staticClass: "mr-2 d-none d-lg-inline-block",
                    attrs: {
                      action: "sidebarMiniToggle",
                      size: "sm",
                      variant: "dual"
                    }
                  },
                  [_c("i", { staticClass: "fa fa-fw fa-ellipsis-v" })]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex align-items-center" },
              [
                _c("div", { staticClass: "mr-2" }, [
                  _c("i", {
                    class: [
                      "fas",
                      _vm.$store.state.app.darkmodea ? "fa-sun" : "fa-moon"
                    ],
                    on: {
                      click: function($event) {
                        return _vm.gl_switchDarkMode()
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "b-dropdown",
                  {
                    ref: "dropdown",
                    staticClass: "d-inline-block",
                    attrs: {
                      size: "sm",
                      variant: "dual",
                      "menu-class": "p-0 font-size-sm",
                      right: "",
                      "no-caret": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button-content",
                        fn: function() {
                          return [
                            _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.name) +
                                  "\n            "
                              )
                            ])
                          ]
                        },
                        proxy: true
                      }
                    ])
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "b-dropdown-text",
                      {
                        staticClass: "cursor-pointer py-1 font-common dd-drop",
                        on: { click: _vm.pengaturan }
                      },
                      [_vm._v("\n            Akun\n          ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-dropdown-text",
                      {
                        staticClass: "cursor-pointer py-1 font-common dd-drop",
                        on: { click: _vm.logout }
                      },
                      [_vm._v("\n            Logout\n          ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }